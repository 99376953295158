<template>
    <div class="content">
        <h3 style="font-weight: normal"><span style="font-weight: bold"> {{user.nickName}} </span>您好!</h3>
        <h4 style="font-weight: inherit">在您的會員中心裡，您可以檢視 近期的訂單，管理您的 收貨地址 和 更改密碼或修改帳號資料。</h4>
        <el-row>
        <el-col :span="8" v-for="(item, index) in menus" :key="index" style="margin: 10px  auto;">
            <el-card shadow="hover">
                <div class="block" align="center" >
                    <router-link :to="item.url" :title="item.name" style="text-decoration: none;">
                    <i :class="item.icon"></i>
                    <span>{{item.name}}</span>
                    </router-link>
                </div>
            </el-card>
        </el-col>
        </el-row>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    export default {
        name: '',
        components: {

        },
        data() {
            return {
                menus: [
                    {index: 1, url: "/user-center/orders", icon: "el-icon-s-order", name: "我的訂單"},
                    {index: 2, url: "/user-center/address", icon: "el-icon-s-home", name: "我的地址"},
                    {index: 3, url: "/user-center/user", icon: "el-icon-s-custom", name: "帳戶資料"},
                    {index: 4, url: "/logout", icon: "el-icon-switch-button", name: "退出登入"}
                ],
            }
        },
        methods: {
        },
        computed:{
            ...mapState(['user']),
        },
        mounted() {
        }
    }
</script>
<style lang="less" scoped>
    .content{
        padding: 10px;
    }
</style>
